import React from "react"

export default () => (
  <section className="section-contact">
    <span className="contact-title">
      Une question ? Une demande ? Contactez-nous
    </span>
    <form
      method="post"
      netlify-honeypot="bot-field"
      data-netlify="true"
      name="contact"
      id="contact"
      className="form-contact"
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="contact" />
      <input
        type="hidden"
        name="subject"
        data-remove-prefix
        value="Nouveau message %{formName} (%{submissionId})"
      />
      <label className="form-input">
        Nom et Prénom :
        <input type="text" name="name" id="name" />
      </label>
      <label className="form-input">
        Email :
        <input type="email" name="email" id="email" />
      </label>
      <label className="form-input">
        Votre message :
        <textarea name="message" id="message" rows="5" />
      </label>
      <button type="submit" className="post-card-link event-button">
        Envoyez
      </button>
    </form>
  </section>
)
