import React from "react"
import Img from "gatsby-image"

export default ({ facebook, instagram }) => (
  <div className="social-links">
    <a
      href="https://www.facebook.com/profile.php?id=100063620678185"
      title="Facebook Orchestre harmonie Evreux"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Img fluid={facebook} className="kg-image" />
    </a>
    <a
      href="https://www.instagram.com/orchestre_harmonie_evreux/"
      title="Instagram Orchestre harmonie Evreux"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Img fluid={instagram} className="kg-image" />
    </a>
  </div>
)
